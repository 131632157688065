.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
  transition: right 0.3s;

  button {
    padding: 6.4px 15px !important;
    font-size: 1.5rem !important;
  }

  &.hidden {
    right: -50px;
  }

  .toggle-button {
    width: 42px;
    height: 42px;
    position: absolute;
    top: -15px;
    left: -15px;
    z-index: 1001;
    padding: 0 !important;
    color: #c63627;
    background-color: white;
    border-radius: 50% !important;
    display: none;
  }
}

@media (max-width: 600px) {
  .floating-button {
    .toggle-button {
      display: block;
    }
  }
}
