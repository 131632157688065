@media (max-width: 575px) {
    .dashboard-page-layout {
        margin-left: 100px !important;

        .ant-select-selection-item {
            display: block;
        }
    }

    #business-info {
        .form-row {
            .ant-form-item {
                width: 100%;
            }
        }
    }

    #business-settings {
        .form-row {
            .ant-form-item {
                width: 100%;
                flex-direction: row;
            }
        }

        .business-hours-field {
            flex-wrap: wrap;

            .checkbox-field {
                width: 100%;
            }
        }
    }
}