// Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

// Font Variables
$text-font: "Nunito Sans", sans-serif;
$heading-font: "Inter", sans-serif;
$paragraph-size: 1.1rem;
$heading-size: 1.5rem;

// Media Query Sizes
$mobile: 500px;
$tablet: 767px;
$large: 1024px;
$x-large: 1200px;

// Theme Colors
$purple: #8620ce;
$sky-blue: #458dd2;
$red: #c63627;
// c63627;
$hd-grey: #3a3541de;
$md-grey: #cccccc;
$lt-grey: #ebebeb;
$light-bg: #f0f2f5;
$green: #05704f;
$lt-green: #93cc04;
$yellow: #fcc204;
