.report-modal {
  .reportBusinessTitle {
    font-weight: bold;
    font-size: 18px;
    align-items: center;

    .flagReportIcon {
      color: $red;
    }
  }

  .handleBusinessReportBtnContainer {
    display: flex;

    button {
      margin-right: 10px;
    }
  }

  .selectProblemText {
    font-weight: bold;
    font-size: 18px !important;
    display: block;
    margin-bottom: 15px;
  }
}
