.dashboard-sidebar {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
  background: $green;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;

  .ant-layout-sider-trigger {
    background: black;
  }

  .ant-menu-inline-collapsed {
    .ant-menu-submenu-selected {
      background: $lt-green;
    }
  }

  #business-dashboard-menu {
    padding-bottom: 60px;
  }

  .logo {
    font-weight: 700;
    font-size: 1.1rem;
    color: white;
    font-family: $heading-font;
    padding: 15px 15px 0;
    text-align: center;
    text-transform: uppercase;

    a {
      color: white;
    }
  }

  .business-list-manager {
    margin: 16px;

    .business-selector {
      width: 100%;
      background: none;
      border-radius: 5px;
      cursor: pointer;

      .ant-select-selector {
        cursor: pointer;
        overflow: hidden;

        .ant-select-selection-item {
          font-size: 1rem;
          overflow: hidden;
          white-space: nowrap;
          width: 85%;
          display: block;
        }

        input {
          cursor: pointer;
          font-size: 0.9rem;
        }

        &::after {
          content: none;
        }
      }
    }

    button {
      width: 100%;
      border-radius: 5px;
      background: none;
      border: white 1px solid;
      color: white;
      padding: 10px;
      font-size: 1.1rem;
      cursor: pointer;

      &:hover {
        background: white;
        color: $purple;
      }
    }
  }

  .dashboard-menu {
    background: none;
    font-size: 1.1rem;

    .ant-menu-item-selected {
      background: $lt-green !important;
    }

    .ant-menu-submenu-inline {
      margin-bottom: 10px;
    }

    .ant-menu-sub {
      background: none;
      font-size: 1rem;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  background: $green;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: $lt-green;
}

@media (max-width: 575px) {
  aside:not(.ant-layout-sider-collapsed) {
    width: 100% !important;
    max-width: 100% !important;
    z-index: 10;

    .ant-layout-sider-trigger {
      width: 100% !important;
    }
  }
}
