#header-user-bar {
  display: flex;
  width: 100%;
  justify-self: flex-end;
  margin-left: auto;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;

  .back-to-bizzll {
    justify-self: flex-start;
    margin-right: auto;

    img {
      max-width: 120px;
    }
  }

  .vertical-divider {
    width: 2px;
    background: #bebebe;
    height: 30px;
    margin: 0 25px;
  }

  .user-avatar {
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid transparent;

    .ant-avatar-circle {
      background: #fcc204;
      color: #000;
      font-weight: 600;
    }
  }

  .userbar-btns {
    border: none;
    background: none;
    padding: 0 !important;
    cursor: pointer;
    margin: 0 15px;

    svg {
      font-size: 1.5rem;
      color: $red;
    }
  }

  .extra-buttons {
    background: $red;
    border: $red 2px solid;
    font-size: 1.2rem;
    cursor: pointer;
    color: white;
    padding: 5px 15px;
    border-radius: 8px;
    margin: 0 10px;
  }
}

.ant-popover-inner-content {
  .header-user-menu {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        line-height: normal;

        a {
          display: block;
          color: black;
          padding: 10px 25px;
          font-family: $heading-font;

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

.header-notifications-box {
  width: 300px;

  .notification-item {
    padding: 5px;
    border: 1px solid $lt-grey;
    display: flex;
    justify-content: space-between;
    color: black;
    align-items: center;
    position: relative;

    .not-read {
      background-color: $red;
      display: block;
      position: absolute;
      top: 40%;
      left: -5px;
      color: white;
      padding: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      overflow: hidden;
    }

    &.comment {
      .icon {
        background-color: $sky-blue !important;
      }
    }

    &.like {
      .icon {
        background-color: $red !important;
      }
    }

    .logo {
      width: 50px;
      margin-right: 15px;
      position: relative;

      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: $green;
        border-radius: 50%;
        font-size: 0.7rem;
      }
    }

    .notification-txt {
      span {
        font-weight: 900;
      }

      .time {
        color: $hd-grey;
        font-size: 0.8rem;
        text-align: right;
      }
    }
  }
}

.message-notification-user-bar {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 5px;
  min-height: 40px;
  display: flex;
  align-items: center;
  max-width: 320px;

  &.business-notification {
    cursor: pointer;
  }
}

@media (max-width: 475px) {
  .vertical-divider {
    display: none;
  }
}
