.appointments_container-calendar {
  display: flex;
  flex-wrap: wrap;

  .appointments_container-calendar_calendar {
    width: 48%;
    padding: 30px;
    margin: 0 1%;
    border: 1px solid #cfd6e5 !important;
    border-radius: 15px 15px 0 15px;
    box-shadow: 0 2px 6px rgba(166, 176, 196, 0.04),
      0 4px 12px rgb(166, 176, 196, 0.12);

    .ant-select-selector {
      padding: 0px 15px !important;
    }
  }

  .appointments_container-calendar_turn {
    width: 48%;
    padding: 30px;
    margin: 0 1%;
    border: 1px solid #cfd6e5 !important;
    border-radius: 15px 15px 0 15px;
    box-shadow: 0 2px 6px rgba(166, 176, 196, 0.04),
      0 4px 12px rgb(166, 176, 196, 0.12);
    display: flex;
    flex-wrap: wrap;
    place-content: space-around;

    .single-turn-container {
      width: 120px;
      padding: 10px;
      overflow-x: auto;
      box-shadow: 0 2px 6px rgba(166, 176, 196, 0.04),
        0 4px 12px rgb(166, 176, 196, 0.12);
      display: flex;
      position: relative;
      float: inline-start;
      margin: 5px;
      text-align: center;
      border-radius: 15px 15px 0 15px;
      place-content: center;

      .single-turn-container_select {
        width: 100%;
        background-color: transparent;
        border: 1px solid;
        border-radius: 15px 15px 0 15px;
        border-color: #cfd6e5 !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 10;
      }

      .selected_turn {
        border-color: $red !important;
        box-shadow: 0 8px 16px rgba(114, 124, 149, 0.12),
          0 4px 8px rgba(114, 124, 149, 0.12);
      }
    }
  }
}

.back-to-list {
  button {
    color: rgba(58, 53, 65, 0.8705882353);
    background: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 15px;
    border: 1px solid rgba(58, 53, 65, 0.8705882353);
    margin-bottom: 20px;
    margin-left: 1%;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media (max-width: 768px) {
  .appointments_container-calendar .appointments_container-calendar_calendar {
    width: 100%;
    margin-bottom: 20px;
  }

  .appointments_container-calendar .appointments_container-calendar_turn {
    width: 100%;
  }
}
