.messages-box-wrapper {
  display: flex;
  height: 600px;
  border: 1px solid $lt-grey;

  .thread-list {
    width: 30%;
    border-right: 1px solid $lt-grey;

    .search-box {
      padding: 15px;
      border-bottom: 1px solid $lt-grey;
    }

    .thread-item {
      border-bottom: 1px solid $lt-grey;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      transition: 0.3s all;

      .user-avatar {
        width: 10%;

        .ant-avatar {
          background: $yellow;
          color: black;
        }
      }

      .user-info {
        width: 87%;

        .meta {
          display: flex;
          justify-content: space-between;

          h4 {
            font-weight: 600;
            font-size: 1rem;
            margin: 0;
          }

          .relative-time {
            color: $hd-grey;
            font-style: italic;
          }
        }

        .recent-message {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $hd-grey;
        }
      }

      &.active {
        background: $lt-grey;
      }

      &:hover {
        background: $lt-grey;
      }
    }
  }

  .message-box {
    width: 70%;

    .no-thread-selected {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 6rem;
      color: $red;
      background-color: $lt-grey;
      flex-direction: column;

      h3 {
        font-size: 1.2rem;
      }
    }

    .chat-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 15px;

      .message-wrapper {
        display: flex;
        margin-bottom: 15px;

        .user-avatar {
          margin-right: 10px;

          .ant-avatar {
            background: $yellow;
            color: black;
            width: 10%;
          }
        }

        .message {
          width: 60%;
          background: $lt-grey;
          color: black;
          font-weight: 600;
          padding: 10px;
          border-radius: 8px;
        }

        .date-sent {
          justify-self: flex-end;
          margin-left: auto;
          padding: 10px;
          font-size: 0.7rem;
          color: $hd-grey;
          font-style: italic;
        }

        &.recipient {
          flex-direction: row-reverse;

          .date-sent {
            margin-right: auto;
            margin-left: 0 !important;
          }

          .user-avatar {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
