.dashboard-page-layout {
  margin-right: 20px;
  max-width: 1400px;
  width: 100%;
  transition: all 0.3s;

  .back-to-list {
    margin: 15px 0;

    button {
      border: none;
      color: $hd-grey;
      background: none;
      font-size: 1.2rem;
      cursor: pointer;
      padding: 15px;
      border: 1px solid $hd-grey;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  #dashboard-header {
    display: flex;
    background: none !important;
    padding: 0;
  }

  #dashboard-content {
    padding: 0;
    margin: 0;
    min-height: 280;

    .preview-btn {
      background: $red;
      border: $red 2px solid;
      color: white;
      font-size: 1.3rem;
      top: 0;
      right: 0;
      padding: 5px 15px;
    }

    .content-box {
      box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
      background: white;
      border-radius: 10px;
      padding: 20px;
      position: relative;

      .warning-boxes {
        margin-bottom: 15px;

        .ant-alert-message {
          font-weight: 600;
        }
      }

      #service_price_duration {
        margin-top: 30px;

        label {
          margin-right: 20px;
        }

        .service_price_duration_center {
          display: flex;
          width: 50%;
          place-content: center;
          align-items: center;

          .ant-form-item {
            width: auto;
            margin: 0;

            .ant-input-number {
              width: 120px;
              margin-right: 10px;
            }
          }
        }
      }

      #service_free {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 20px auto;

        .ant-form-item-control-input {
          place-content: center;

          .ant-form-item-control-input-content {
            display: contents;

            label {
              margin-right: 20px;
            }

            input {
              width: auto;
            }
          }
        }
      }

      #appointments {
        display: flex;
        margin-bottom: 40px;

        .min-time-appointments {
          width: 100%;
          display: flex;
          align-items: center;
          place-content: center;

          .ant-form-item {
            margin: 0 0 0 10px;
          }
        }
      }

      h2 {
        text-align: center;
      }

      #availability-rules {
        width: 100%;
        margin: 30px auto;

        .availability-rules-hours {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .availability-rules_range-of-hours {
            width: 500px;
            margin: 10px;
            padding: 15px;
            border: 1px solid darkgray;
            border-radius: 5px;
            .availability-rules-hours-row {
              margin-top: 5px;
              margin-bottom: 5px;
            }
          }

          .availability-rules-form {
            display: flex;
            align-items: center;
            //padding: 20px;

            .ant-select-selector {
              display: flex !important;
            }

            .availability-rules-form_from {
              width: 45%;
              display: flex;
              place-content: center;
              align-items: center;

              label {
                margin-right: 10px;
                width: 20%;
              }

              .ant-picker-footer {
                display: "none";
              }
            }

            .availability-rules-form_to {
              width: 45%;
              display: flex;
              place-content: center;
              align-items: center;

              label {
                margin-right: 10px;
                width: 20%;
              }
            }

            .deleteContainer {
              font-size: 20px;
              width: 10%;
              text-align: center;
              color: $red;

              .anticon-delete {
                padding: 10px;
                border-radius: 50%;
                background-color: $red;
                color: white;
                border: 2px solid $red;

                &:hover {
                  cursor: pointer;
                  transition: all 0.3s ease;
                  background-color: white;
                  color: $red;
                }
              }
            }

            .ant-select-selection-overflow-item-suffix {
              display: none;
            }
          }

          #availability_add {
            background-color: #05704f;
            border-color: #05704f;
            margin-top: 20px;
          }

          #availability_add:hover {
            background: white;
            color: #05704f;
            border-color: #05704f;
          }
        }
      }

      #service_save {
        float: right;
        background-color: rgb(101, 88, 245);
        border-color: rgb(101, 88, 245);
      }

      #service_save:hover {
        background: white;
        color: rgb(101, 88, 245);
        border-color: rgb(101, 88, 245);
      }
    }

    h3.page-heading {
      color: $red;
      font-weight: 900;
      font-size: 1.2rem;
      margin: 20px 0;
      text-transform: uppercase;

      span {
        width: 48px;
        height: 48px;
        color: white;
        font-size: 20px;
        line-height: 48px;
        text-align: center;
        margin-right: 16px;
        background: $red;
        display: inline-block;
        border-radius: 5px;
      }
    }

    #appointments-page .ant-radio-group {
      display: none;
    }
  }

  .group-invite {
    margin-bottom: 20px;
  }

  .group-members {
    .is-blocked {
      color: $red;
      text-decoration: line-through;
      font-style: italic;
    }
  }
}

.invite-modal {
  .email-list {
    margin-top: 15px;
  }
}

#page-loader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $purple;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5rem;
}

.bank-item {
  .ant-collapse-header {
    align-items: center !important;
  }

  .bank-header {
    display: flex;
    align-items: center;

    .icon {
      font-size: 2rem;
      color: $red;
      margin-right: 10px;
    }

    .bank-info {
      font-size: 1rem;
      color: $hd-grey;
      font-weight: 600;

      span {
        display: block;
        font-size: 0.8rem;
      }
    }
  }

  .remove-btn {
    text-align: right;
  }
}

#restaurants {
  .form-column-left {
    width: 80%;
    padding-right: 20px;

    .ant-form-item {
      width: 100%;
    }

    textarea {
      height: 200px;
    }
  }

  .form-column-right {
    width: 16%;

    .ant-form-item {
      width: 80%;

      .ant-upload {
        width: 100%;
        height: 167px;
        max-height: 167px;
        max-width: 167px;
        margin: 0;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: $large) {
  #dashboard-content {
    .content-box {
      .form-column-left {
        width: 100% !important;
      }

      .form-column-right {
        width: 100% !important;
      }

      #appointments {
        flex-direction: column;

        .min-time-appointments {
          place-content: normal !important;
        }
      }

      #availability-rules {
        width: 90% !important;
      }
    }
  }
}
