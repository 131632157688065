.payout-wrapper {
  padding: 20px;
  border: 1px #ebebeb solid;

  .payout-balances {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ebebeb;

    .balance-wrapper {
      width: 200px;

      p {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0;
      }

      h3 {
        font-size: 2rem;
      }
    }

    .payout-btn {
      justify-self: flex-end;
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;

      .payout-popup {
        background: $purple;
        border: $purple 2px solid;
        color: white;
        display: flex;
        width: 40px;
        height: 40px;
        margin-left: 10px;
        border-radius: 5px;
        overflow: hidden;
        font-size: 1.3rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: none !important;
          color: $purple;
        }
      }
    }
  }
}

.payout-menu {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    padding: 10px 25px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
