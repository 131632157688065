.affailiate-dashboard {
  .referral-url {
    display: flex;

    .referral-url-txt {
      padding: 10px 15px;
      border: $md-grey 1px solid;
      font-size: 1.1rem;
      margin-right: 10px;
      border-radius: 8px;
      width: 400px;
    }

    .ant-input {
      width: 500px;
      margin-right: 10px;
    }
  }
}
