body {
  font-family: $text-font;
  color: $hd-grey;
  background: $light-bg;

  .verify-buttons {
    display: flex;

    button {
      margin-right: 15px;
    }
  }

  .row-group {
    display: flex;
  }

  .ant-btn-default {
    background: $red;
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 5px 25px;
    height: auto !important;
    border: $red 2px solid;
    border-radius: 8px;

    &:hover {
      background: white;
      color: $red;
      border-color: $red;
    }
  }

  .ant-table-wrapper {
    .ant-table {
      overflow-x: auto;
    }
  }

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: $lt-grey !important;
        border-color: $md-grey !important;
        border-width: 2px !important;
        font-size: 1.1rem !important;
        border-bottom: $lt-grey 1px solid !important;
        border-radius: 8px 8px 0 0 !important;

        &:hover {
          background-color: white !important;

          .ant-tabs-tab-btn {
            color: $hd-grey !important;
          }
        }

        &.ant-tabs-tab-active {
          background-color: $green !important;
          color: white !important;
          border-color: $green !important;

          .ant-tabs-tab-btn {
            color: white !important;
          }
        }
      }
    }

    &::before {
      border-color: $light-bg !important;
    }
  }

  .ant-tabs-content-holder {
    padding: 0 20px !important;
  }

  .ant-btn-primary {
    background: $red;
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 5px 25px;
    height: auto !important;
    border: $red 2px solid;
    border-radius: 8px;

    &:hover {
      background: white;
      color: $red;
      border-color: $red;
    }
  }

  .ant-table-thead {
    th {
      background: $green;
      font-weight: 700;
      color: white;
      font-size: 1.1rem;
    }
  }

  .ant-table-tbody {
    tr {
      font-size: 1rem;

      a {
        color: $red !important;
        font-weight: 700;
      }

      button {
        border: $red 2px solid;
        background: white;
        cursor: pointer;
        padding: 5px 10px;
        font-weight: 700;

        &:hover:enabled {
          background: $red;
          color: white;
        }

        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      &:nth-child(odd) {
        background: #ebebeb !important;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $heading-font;
  }

  .ant-layout {
    background: none !important;
  }

  .ant-select-selection-item {
    button {
      display: none !important;
    }
  }

  .form-subtitle {
    font-weight: 600;
    margin-bottom: 25px;
    color: #888;

    span {
      display: block;
      color: black;
      font-weight: 300;
    }
  }

  .tags-list {
    margin-bottom: 10px;

    .ant-tag {
      font-size: 1rem;
      padding: 10px;

      .anticon {
        color: $red;
        font-size: 1rem;

        &:hover {
          color: $sky-blue;
        }
      }
    }
  }

  form {
    .business-hours-field {
      display: flex;
      align-items: center;

      .checkbox-field {
        width: 150px;
      }

      .ant-picker {
        margin-right: 15px;
      }
    }

    .form-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .ant-form-item {
        width: 48%;
      }
    }

    label {
      font-size: 1.1rem !important;
      color: $hd-grey !important;
      font-weight: 600;

      .ant-form-item-tooltip {
        color: $red !important;
        font-size: 1.2rem !important;
        padding-left: 5px;
      }
    }
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-select-focused .ant-select-selector,
  .ant-input-affix-wrapper-focused,
  .ant-picker-focused,
  .ant-input-number-focused {
    border-color: $sky-blue !important;
    box-shadow: 0 0 0 1px $sky-blue !important;
  }

  .ant-input {
    padding: 10px 15px;
    display: block;
    border-radius: 8px;
    font-size: 1.1rem;
    color: $hd-grey;
  }

  .ant-input-number {
    padding: 10px 15px;
    display: block;
    border-radius: 8px;
    font-size: 1.1rem;
    color: $hd-grey;
  }

  .ant-select-selector {
    height: auto !important;
    padding: 10px 15px !important;
    display: block !important;
    border-radius: 8px !important;
    font-size: 1.1rem !important;
    color: $hd-grey !important;
  }

  .ant-select-selection-search-input {
    height: auto !important;
    padding: 15px 5px !important;
    display: block !important;
    border-radius: 8px !important;
    font-size: 1.1rem !important;
    color: $hd-grey !important;
  }

  .ant-picker {
    height: auto !important;
    border-radius: 8px !important;
    font-size: 1.1rem !important;
    color: $hd-grey !important;

    .ant-picker-input input {
      padding: 10px 5px !important;
      display: block !important;
    }
  }

  .ant-input-password {
    border-radius: 8px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding: 5px !important;
  }

  .calendar_table_container {
    display: flex;
    flex-direction: column;

    .calendar_container {
      width: 100%;
      margin-right: 25px;
      border: 1px solid #f0f0f0;
      border-radius: 15px 15px 0 15px;
      overflow: hidden;
    }
  }

  .helpButton {
    font-size: 32px;
    position: absolute;
    z-index: 1;
    color: $red;
    transition: all 0.3s;

    &:hover {
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

//* General Elements */
button,
a {
  transition: 0.3s all;
}
