.chats-container {
  display: flex;
  max-width: 900px;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ebebeb;

  .chats-list {
    width: 50%;
    height: 465px;
    display: flex;
    flex-direction: column;
    border-radius: 8px 0 0 8px;
    overflow: hidden;
    border-right: 1px solid #ebebeb;
    overflow-y: auto;
    padding: 5px;

    .chat-element {
      width: 100%;
      height: 70px;
      padding: 10px;
      font-weight: 600;
      border-radius: 8px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      place-content: space-between;

      .unseen {
        width: 20px;
        height: 20px;
        background-color: $red;
        border-radius: 5px;
        text-align: center;
        color: white;
      }

      &:hover {
        cursor: pointer;
        background-color: #ebebeb65;
      }
    }

    .selected {
      background-color: #ebebeb;
    }
  }

  /** Chat Box **/
  .new-chatbox {
    width: 50%;
    height: 465px;
    position: relative;
    background: white;
    border-radius: 0 8px 8px 0;
    overflow: hidden;

    .chatbox-header {
      display: flex;
      justify-content: space-between;
      background: #8620ce;
      color: white;
      padding: 3px 5px;
      font-size: 10pt;
      line-height: 20px;

      .recipient-name {
        font-weight: bold;
        display: flex;

        p {
          margin: 5px;
        }
      }

      .back {
        display: none;
      }
    }

    .messages-box {
      flex-basis: auto;
      height: 350px;
      overflow-y: auto;
      border-bottom: #ebebeb 1px solid;
      padding: 10px;
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }

    .messages-box > :first-child {
      margin-top: auto;
    }

    .ant-form {
      display: flex;
      place-content: space-around;

      .ant-form-item {
        margin: 10px 0;

        #message {
          border: none;
          box-shadow: none;

          &:focus {
            box-shadow: none;
          }
        }

        .ant-col-offset-8 {
          margin: 0;
        }

        button {
          &:hover {
            color: #8620ce;
            border-color: #8620ce;
          }
        }
      }
    }
  }

  .message-wrapper {
    display: flex;

    .user-logo {
      width: 40px;

      img {
        border-radius: 5px;
        overflow: hidden;
      }
    }

    .message-meta {
      margin: 0 10px;
    }

    .message-txt {
      background: #ebebeb;
      width: 200px;
      padding: 5px;
      line-height: 20px;
      font-size: 10pt;
      color: #303030;
      margin-bottom: 5px;
      border-radius: 5px;
      font-weight: 700;
      overflow: hidden;
    }

    .message-author {
      color: $red;
      font-weight: 600;
      line-height: 20px;
      font-size: 10pt;
      font-style: italic;
    }
  }

  .message-wrapper.sender {
    flex-direction: row-reverse;
  }

  .message-wrapper.first-message {
    margin-top: 10px;
    margin-bottom: -5px;
  }

  .message-wrapper.sender .message-txt {
    background: #8620ce;
    color: white;
  }

  .chatbox.closed {
    display: none;
  }

  .send-message {
    margin: 0;
    padding: 10px 10px;
    width: 100%;
    border-top: 1px solid gray;
    background-color: #eee;
    position: relative;
    display: flex;
    align-items: flex-end;

    textarea {
      overflow: hidden;
      margin: 0;
      padding: 10px;
      border: 0;
      outline: 0;
      font-size: 11pt;
      line-height: 20px;
      resize: none;
      border-radius: 10px;
      width: 85%;
      margin-right: 10px;
    }

    .message-btn {
      cursor: pointer;
    }

    .reportTextContainer {
      cursor: pointer;
    }
  }

  .empty-chat {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    place-content: center;
    align-items: center;
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .chats-container {
    flex-direction: column;

    .chats-list {
      width: 100%;
      border: none;
      border-radius: 8px;
    }

    .new-chatbox {
      width: 100%;
      border-radius: 8px;

      .chatbox-header {
        .back {
          display: flex;
          align-items: center;
          width: 20px;
          place-content: center;

          &:hover {
            cursor: pointer;
            font-size: 12pt;
          }
        }
      }
    }

    .message-wrapper {
      .message-txt {
        width: 150px;
      }
    }
  }
}
