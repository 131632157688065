.analytics-box {
  width: 200px;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;
  color: white;
  margin: 0 5px;

  .title {
    font-size: 1rem;

    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .stat {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: right;
  }

  &.purple {
    background: $purple;
  }

  &.red {
    background: $red;
  }

  &.green {
    background: $green;
  }

  &.blue {
    background: $sky-blue;
  }
}
