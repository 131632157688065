#services-page {
  #services {
    .form-column-left {
      padding-right: 0;
      width: 75%;

      .ant-form-item {
        width: 100%;
      }
    }

    .form-column-right {
      width: 25%;
      padding: 2%;

      .ant-form-item {
        width: 100%;

        span {
          height: 250px;
        }
      }
    }

    #service_price_duration {
      flex-direction: column;
      // align-items: center;

      .service_price_duration_center {
        margin-top: 20px;
        place-content: flex-start;
      }
    }

    #appointments {
      flex-direction: column;

      .min-time-appointments {
        width: 100%;
        place-content: normal !important;
        flex-wrap: wrap;

        label {
          width: 100%;
        }

        .ant-form-item {
          width: auto;
        }

        .ant-select {
          height: 52px;
          display: flex;
        }
      }
    }

    #availability-rules {
      width: 100% !important;

      .availability-rules-form {
        flex-direction: column !important;

        .availability-rules-form_from {
          width: 100% !important;
          margin-bottom: 10px;
        }
        .availability-rules-form_to {
          width: 100% !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  #services-page {
    #services {
      .form-column-left {
        padding-right: 0;
      }

      #service_price_duration {
        flex-direction: column;
        // align-items: center;

        .service_price_duration_center {
          margin-top: 20px;
          place-content: flex-start;
        }
      }

      #appointments {
        flex-direction: column;

        .min-time-appointments {
          width: 100%;
          place-content: normal !important;
          flex-wrap: wrap;

          label {
            width: 100%;
          }

          .ant-form-item {
            width: auto;
          }

          .ant-select {
            height: 52px;
            display: flex;
          }
        }
      }

      #availability-rules {
        width: 100% !important;

        .availability-rules-form {
          flex-direction: column !important;

          .availability-rules-form_from {
            width: 100% !important;
            margin-bottom: 10px;
          }
          .availability-rules-form_to {
            width: 100% !important;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 775px) {
  #appointments-page {
    .calendar_table_container {
      flex-direction: column;

      .calendar_container {
        width: 100%;
      }

      .appointment_table_container {
        margin-top: 30px;
      }
    }
  }
}
