.followers-list-wrapper {
  .followers-list-header {
    margin-bottom: 25px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .ant-avatar {
    background: $yellow;
  }

  .ant-card-actions li {
    color: $red;
  }
}
