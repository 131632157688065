#events-page {
  .event-tickets-list {
    margin-top: 30px;
  }
}

.attendee-modal {
  .export-csv {
    margin-bottom: 25px;
    a {
      background: $red;
      color: white;
      font-size: 1.1rem;
      font-weight: 700;
      padding: 5px 25px;
      height: auto !important;
      border: $red 2px solid;
      border-radius: 8px;

      &:hover {
        color: $red;
        background: none;
      }
    }
  }
}

@media (max-width: 575px) {
  #events-page {
    .form-row {
      flex-direction: column;

      .ant-form-item {
        width: 100%;
      }
    }
  }

  #basic {
    .ant-form-item {
      width: 100%;
    }
  }
}

