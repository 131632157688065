.analytics-container {
  margin-top: 25px;

  .date-range {
    margin-bottom: 25px;
  }

  .main-stats {
    display: flex;
    margin-bottom: 25px;
    justify-content: center;
  }

  .graph {
    margin-bottom: 35px;
  }

  .demographics {
    h3 {
      margin-bottom: 15px;
      font-size: 2rem;
      font-weight: 700;
    }
    .demographic-table {
      margin-bottom: 15px;
    }
  }
}
