#user-settings-header {
  background: white !important;
  box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
  margin-bottom: 40px;
}

#user-settings {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  .content-header {
    margin-bottom: 20px;
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    .ant-avatar-circle {
      background: #fcc204;
      color: #000;
      font-weight: 600;
    }

    .user-details {
      margin-left: 25px;
      font-weight: 700;

      span {
        display: block;
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
  }

  .site-layout-content {
    box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
    background: white;
    border-radius: 10px;
    padding: 20px;
  }

  .dashboard-menu {
    background: none;
    font-size: 1.1rem;
    border-radius: 5px;

    .ant-menu-item {
      color: black;

      &:hover {
        color: white;
        background: $lt-green;
      }
    }

    .ant-menu-item-selected {
      background: $green !important;
    }

    .ant-menu-submenu-inline {
      margin-bottom: 10px;
    }

    .ant-menu-sub {
      background: none;
      font-size: 1rem;
    }
  }
}

@media (min-width: 1367px) {
  #user-settings {
      .barsMenu {
        display: none !important;
      }
  }
}

@media (max-width: 1366px) {
  #user-settings {
    .content-header {
      margin-left: 25px;
    }

    .dashboard-menu {
      display: none !important;
    }

    .barsMenu {
      display: block;
      background: #05704f !important;
      border-color: #05704f !important;
    }

    #orders-page {
      overflow-x: scroll;
    }
  }

  .ant-drawer-open {
    .dashboard-menu {
      border-right: 0 !important;

      .ant-menu-item {
        font-size: 20px;
      }
      
      .ant-menu-item-selected {
        color: white;
        background-color: #05704f;
      }
    }
  }
}

@media (max-width: 720px) {

  .ant-layout-header {
    padding: 0 10px;
  }
}

@media (max-width: 600px) {

  .ant-layout-header {

    #header-user-bar {
      
      .extra-buttons {
        display: none;
      }
    }
  }
}
