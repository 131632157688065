#login-page {
  background: transparent url(../../images/auth-bg-pattern.png) center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .link-btn {
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: 700;
    color: $sky-blue;
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ant-row {
      width: 100%;

      button {
        width: 100%;
      }
    }

    .link-btn {
      margin-bottom: 0 !important;
    }
  }

  .form-content {
    padding: 25px;
    background: white;
    z-index: 999;
    width: 100%;
    max-width: 500px;
    margin: 60px 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;

    .logo {
      text-align: center;
      display: block;
      margin-bottom: 25px;

      img {
        width: 250px;
      }
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &::before {
    content: "";
    background-color: rgba(63, 81, 181, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .captcha {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
}

.terms-modal {
  .ant-modal-footer button:first-child {
    display: none !important;
  }
}
