.message-page {
  background: transparent url(../../images/auth-bg-pattern.png) center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  position: relative;
  display: flex;
  height: 100vh;
  overflow-y: auto;
  align-items: center;
  justify-content: center;

  .message-box {
    background: $light-bg;
    z-index: 10;
    padding: 30px;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
  }

  &::before {
    content: "";
    background-color: rgba(63, 81, 181, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
